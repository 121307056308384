import { InstancedMesh } from '@babylonjs/core/Meshes/instancedMesh';
import { Mesh } from '@babylonjs/core/Meshes/mesh';
import { getNodeKey } from '../getNodeKey';
import { nodeHasTag } from '../nodeHasTag';
import { initBox } from './initBox';
import { initInteractable } from './initInteractables';
import { initMeshCollidable } from './initMeshCollidable';
import { initMeshCollidableFromBoundingBox } from './initMeshCollidableFromBoundingBox';
import { initTarget } from './initTarget';
const setReceiveShadows = (m) => {
    if (m instanceof Mesh) {
        m.receiveShadows = true;
    }
};
const initMesh = (ctx, mesh) => {
    const key = getNodeKey(mesh);
    const shadowGenerator = ctx.getShadowGenerator();
    mesh.alwaysSelectAsActiveMesh = true;
    let checkInteractive = true;
    switch (key) {
        case 'Barrier':
            mesh.setParent(null);
            mesh.isVisible = false;
            initMeshCollidable(ctx, mesh);
            break;
        case 'PStaticBox':
            let interactable = null;
            if (mesh.skeleton !== null) {
                if (mesh.parent !== null) {
                    if (nodeHasTag(mesh.parent, 'Interactive')) {
                        mesh.isPickable = false;
                        interactable = initInteractable(ctx, mesh);
                        shadowGenerator.addShadowCaster(mesh);
                        checkInteractive = false;
                    }
                }
                // mesh.skeleton.overrideMesh = null;
            }
            mesh.setParent(null);
            setReceiveShadows(mesh);
            if (mesh.skeleton !== null) {
                const box = initMeshCollidableFromBoundingBox(ctx, mesh);
                if (interactable !== null) {
                    interactable.setCollideMesh(box);
                }
            }
            else {
                initMeshCollidable(ctx, mesh);
            }
            break;
        case 'PStaticBoxPhysics':
            mesh.setParent(null);
            setReceiveShadows(mesh);
            initMeshCollidable(ctx, mesh);
            break;
        case 'PStatic':
            setReceiveShadows(mesh);
            break;
        case 'PBox':
            initBox(ctx, mesh);
            setReceiveShadows(mesh);
            shadowGenerator.addShadowCaster(mesh);
            break;
        default:
            break;
    }
    if (checkInteractive && nodeHasTag(mesh, 'Interactive')) {
        initInteractable(ctx, mesh);
    }
    if (nodeHasTag(mesh, 'Target')) {
        initTarget(ctx, mesh);
    }
    if (nodeHasTag(mesh, 'NoPick')) {
        mesh.isPickable = false;
    }
    if (nodeHasTag(mesh, 'SavedSpot')) {
        mesh.isVisible = false;
    }
    if (nodeHasTag(mesh, 'StartPoint')) {
        mesh.isVisible = false;
    }
};
export const initMeshes = (ctx) => {
    ctx.getScene().meshes.forEach((mesh) => {
        if (!(mesh instanceof Mesh) && !(mesh instanceof InstancedMesh)) {
            return;
        }
        initMesh(ctx, mesh);
    });
};
