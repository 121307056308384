import { StandardMaterial } from '@babylonjs/core/Materials/standardMaterial';
import { DynamicTexture } from '@babylonjs/core/Materials/Textures/dynamicTexture';
import { Color3 } from '@babylonjs/core/Maths/math.color';
import { Mesh } from '@babylonjs/core/Meshes/mesh';
import { MeshBuilder } from '@babylonjs/core/Meshes/meshBuilder';
const planeWidth = 6;
const planeHeight = 3;
const textureWidth = planeWidth * 60;
const textureHeight = planeHeight * 60;
const fontFamily = 'Lobster Two, monospace';
export class LevelLabel {
    mesh;
    texture;
    color = 'black';
    text = '';
    constructor(ctx, name) {
        const scene = ctx.getScene();
        this.mesh = MeshBuilder.CreatePlane(`${name}-plane`, { width: planeWidth, height: planeHeight }, scene);
        this.mesh.billboardMode = Mesh.BILLBOARDMODE_ALL;
        const material = new StandardMaterial(`${name}-text-material`, scene);
        this.texture = new DynamicTexture(`${name}-texture`, { width: textureWidth, height: textureHeight }, scene, true);
        material.diffuseTexture = this.texture;
        material.diffuseTexture.hasAlpha = true;
        material.useAlphaFromDiffuseTexture = true;
        material.specularPower = 0;
        material.specularColor = Color3.Black();
        this.mesh.material = material;
        this.mesh.renderingGroupId = 1;
    }
    getMesh() {
        return this.mesh;
    }
    setColor(color) {
        const needsRender = color !== this.color;
        this.color = color;
        if (needsRender) {
            this.renderText(this.text, true);
        }
    }
    renderText(text, force = false) {
        if (!force && text === this.text) {
            return;
        }
        this.text = text;
        const size = 11;
        const { texture } = this;
        const ctx = texture.getContext();
        ctx.canvas.width = textureWidth;
        ctx.font = `${size}px ${fontFamily}`;
        const textWidth = ctx.measureText(text).width;
        const ratio = textWidth / size;
        const fontSize = Math.floor(textureWidth / (ratio * 1));
        const font = `${fontSize}px ${fontFamily}`;
        ctx.save();
        ctx.filter = 'blur(6px)';
        texture.drawText(text, null, null, font, 'black', 'transparent', true);
        ctx.restore();
        ctx.save();
        ctx.filter = 'blur(1px)';
        texture.drawText(text, null, null, font, 'black', 'transparent', true);
        ctx.restore();
        texture.drawText(text, null, null, font, this.color, 'transparent', true);
    }
    dispose() {
        this.mesh.dispose();
        this.texture.dispose();
    }
}
