export const updateDebug = (ctx) => {
    if (ctx.getControls().debug.isJustPressed()) {
        ctx.toggleIsDebugEnabled();
        const isDebug = ctx.getIsDebugEnabled();
        if (FDS_INSPECTOR) {
            const { debugLayer } = ctx.getScene();
            if (isDebug) {
                if (!debugLayer.isVisible()) {
                    debugLayer.show();
                }
            }
            else if (debugLayer.isVisible()) {
                debugLayer.hide();
            }
        }
    }
    ctx.getCameraTarget().isVisible = ctx.getIsDebugEnabled();
};
