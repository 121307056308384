import { Vector3 } from '@babylonjs/core/Maths/math.vector';
import { Hub } from '../Hub';
export const updatePlayer = (ctx) => {
    const player = ctx.getPlayer();
    player.update();
    const target = ctx.getTarget();
    target.setVisible(false);
    let currentInteractable = null;
    let minDistanceSofFar = Infinity;
    ctx.interactables.forEach((i) => {
        const distance = Vector3.Distance(ctx.getPlayer().getPosition(), i.getPosition());
        if (distance < i.getMinDistance() && distance < minDistanceSofFar
            && (!i.getSaved() || i.getInteractor().focusAfterSaving())) {
            currentInteractable = i;
            minDistanceSofFar = distance;
        }
        else {
            i.setWasInRange(false);
            i.setIsTalking(false);
        }
        i.update();
    });
    player.setCurrentInteractable(currentInteractable);
    // eslint-disable-next-line
    if (currentInteractable !== null) {
        const i = currentInteractable;
        if (!i.getWasInRange()) {
            i.setWasInRange(true);
            const greeting = i.getSaved()
                ? i.getInteractor().getSavedGreeting()
                : i.getInteractor().getGreeting();
            if (greeting !== null) {
                Hub.caption.emit(greeting, 1000);
                Hub.say.emit(greeting, { pitch: i.getPitch() });
            }
        }
        const inLevel = i.getLevel() <= player.getLevel();
        if (!i.getSaved() && inLevel) {
            if (!i.getIsTalking()) {
                Hub.prompt.emit('Press SPACE to chat');
            }
            if (!i.getIsTalking() && ctx.getControls().interact.isJustPressed()) {
                i.setHealth(i.getBaseHealth());
                Hub.spell.emit(i.getInteractor().getSpell());
                Hub.caption.emit(null);
                i.setIsTalking(true);
            }
            target.setPosition(i.getTopPosition());
            target.setVisible(true);
        }
    }
    target.update();
};
