import { jsx as _jsx } from "react/jsx-runtime";
import { Component } from 'react';
import { Hub } from '../Hub';
import './caption.scss';
export class Caption extends Component {
    offCaption = Hub.caption.watch((text, duration) => {
        this.setState({
            text,
            duration: duration ?? 0,
        });
    });
    handle = null;
    state = {
        text: null,
        duration: 0,
    };
    componentDidUpdate(_prevProps, prevState) {
        if (this.state.text !== prevState.text) {
            if (this.handle !== null) {
                window.clearTimeout(this.handle);
                this.handle = null;
            }
            if (this.state.text !== null) {
                this.handle = window.setTimeout(() => {
                    this.handle = null;
                    this.setState(() => ({ text: null }));
                }, this.state.duration);
            }
        }
    }
    componentWillUnmount() {
        this.offCaption();
        if (this.handle !== null) {
            window.clearTimeout(this.handle);
            this.handle = null;
        }
    }
    render() {
        const { text } = this.state;
        if (text === null) {
            return null;
        }
        return (_jsx("div", { className: "caption", children: _jsx("div", { className: "caption__text", children: text }, void 0) }, void 0));
    }
}
