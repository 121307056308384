import '@babylonjs/loaders/glTF/2.0/Extensions/KHR_lights_punctual';
import '@babylonjs/loaders/glTF/2.0/glTFLoader';
import worldUrl from './assets/glb/world.glb';
import { ControlsManager } from './controls/ControlsManager';
import { initCamera } from './init/initCamera';
import { initCameraTarget } from './init/initCameraTarget';
import { initFog } from './init/initFog';
import { initLights } from './init/initLights';
import { initMeshes } from './init/initMeshes';
import { initPlayer } from './init/initPlayer';
import { initSkeletons } from './init/initSkeletons';
import { initPostProcess } from './init/postProcess';
import { updateCamera } from './systems/updateCamera';
import { updateControls } from './systems/updateControls';
import { updateDebug } from './systems/updateDebug';
import { updatePlayer } from './systems/updatePlayer';
export class Game {
    ctx;
    controlsManager;
    constructor(ctx) {
        this.ctx = ctx;
        this.controlsManager = new ControlsManager(ctx);
        window.addEventListener('resize', this.bHandleResize, {
            passive: true,
        });
    }
    dispose() {
        window.removeEventListener('resize', this.bHandleResize);
    }
    bHandleResize = this.handleResize.bind(this);
    handleResize() {
        this.ctx.getEngine().resize();
    }
    preload() {
        this.ctx.getAssetsManager().addMeshTask('world', '', '', worldUrl);
    }
    init() {
        initLights(this.ctx);
        initCamera(this.ctx);
        initSkeletons(this.ctx);
        initPlayer(this.ctx);
        initCameraTarget(this.ctx);
        initMeshes(this.ctx);
        initFog(this.ctx);
        initPostProcess(this.ctx);
        this.ctx.getEngine().setHardwareScalingLevel(2);
    }
    runRenderLoop() {
        this.ctx.getEngine().runRenderLoop(() => {
            this.render();
        });
    }
    startGame() {
        this.ctx.getAudioManager().startGame();
        this.controlsManager.setEnabled(true);
    }
    render() {
        updatePlayer(this.ctx);
        updateDebug(this.ctx);
        this.ctx.getAudioManager().update();
        // Controls goes last
        updateControls(this.ctx);
        updateCamera(this.ctx);
        this.ctx.getScene().render();
    }
}
