import { Ray } from '@babylonjs/core/Culling/ray';
import { Quaternion, Vector3 } from '@babylonjs/core/Maths/math.vector';
import { checkNotNull } from '../checkNotNull';
import { Hub } from '../Hub';
import { lookRotation } from '../lookRotation';
export class CameraController {
    ctx;
    camera;
    originalRotation;
    originalPosition;
    clipCoordinate = new Vector3();
    ray = new Ray(new Vector3(), new Vector3());
    hitOrigin = new Vector3();
    closestHitPoint = new Vector3();
    closestHitOrigin = new Vector3();
    targetQuaternion;
    gameOver = false;
    gameOverAt = Date.now();
    constructor(ctx, camera) {
        this.ctx = ctx;
        this.camera = camera;
        this.originalRotation = camera.absoluteRotation.clone();
        this.originalPosition = camera.position.clone();
        this.targetQuaternion = this.originalRotation.clone();
        Hub.gameOver.on(() => {
            this.gameOver = true;
            this.gameOverAt = Date.now();
        });
    }
    getCamera() {
        return this.camera;
    }
    update() {
        const cameraTarget = this.ctx.getCameraTarget();
        const camera = this.ctx.getCamera();
        const player = this.ctx.getPlayer();
        let nextPos = player.getNextLook();
        const interactable = player.getCurrentInteractable();
        let targetPosition = this.originalPosition;
        let targetRotation = this.originalRotation;
        const targeted = interactable !== null && Hub.spelling.get();
        if (this.gameOver && (Date.now() - this.gameOverAt > 4000)) {
            camera.position.y += 0.1;
            camera.target = player.getPosition();
            return;
        }
        if (targeted) {
            nextPos = interactable.getTopPosition();
            const playerPos = player.getPosition().clone();
            const mid = nextPos.add(playerPos).scaleInPlace(0.5);
            const dir = interactable.getTopPosition().clone().subtractInPlace(player.getPosition());
            const perpendicular = Vector3.Cross(dir, Vector3.Up()).normalize();
            perpendicular.scaleInPlace(Vector3.Distance(nextPos, playerPos) + 10);
            nextPos = mid.add(perpendicular);
            lookRotation(mid.subtract(nextPos).normalize(), this.targetQuaternion);
            targetRotation = this.targetQuaternion;
            targetPosition = nextPos;
        }
        else {
            if (interactable !== null) {
                nextPos = interactable.getPosition();
            }
        }
        // camera.position.copyFrom(this.originalPosition);
        // camera.rotation.copyFrom(this.originalRotation.toEulerAngles());
        Vector3.LerpToRef(cameraTarget.position, nextPos, 0.015 * 4, cameraTarget.position);
        if (!targeted) {
            const forward = camera.getForwardRay().direction.clone();
            forward.y = 0;
            forward.normalize();
            const distance = new Vector3(0, 0, -40);
            forward.scaleInPlace(distance.z);
            targetPosition.copyFrom(cameraTarget.position);
            targetPosition.z += 20;
            targetPosition.x -= 20;
            targetPosition.y += 11;
            lookRotation(nextPos.subtract(targetPosition).normalize(), this.targetQuaternion);
            const r = this.targetQuaternion.toEulerAngles();
            r.z = 0;
            targetRotation = Quaternion.FromEulerVector(r);
        }
        const lerpRate = 0.05;
        Vector3.LerpToRef(this.camera.position, targetPosition, lerpRate, this.camera.position);
        // eslint-disable-next-line
        if (this.camera.rotationQuaternion === undefined) {
            this.camera.rotationQuaternion = new Quaternion();
        }
        const q = this.camera.rotationQuaternion;
        Quaternion.SlerpToRef(q, targetRotation, lerpRate, q);
        this.updateCameraCollision();
    }
    updateCameraCollision() {
        const aspectRatio = (this.ctx.getEngine().getAspectRatio(this.camera));
        const { fov, minZ } = this.camera;
        const z = minZ;
        const x = Math.tan(fov / 2) * z;
        const y = x / aspectRatio;
        this.clipCoordinate.set(x, y, z);
        const targetPosition = this.ctx.getPlayer().getTopPosition();
        this.ray.origin.copyFrom(targetPosition);
        const topLeft = [-1, 1];
        const topRight = [1, 1];
        const bottomRight = [1, -1];
        const bottomLeft = [-1, -1];
        let closestHitDistance = Infinity;
        let closestHitPoint = null;
        [topLeft, topRight, bottomRight, bottomLeft].forEach((offset) => {
            this.hitOrigin.copyFrom(this.clipCoordinate);
            const edgeOfScreenClipFudgeFactor = 2;
            this.hitOrigin.x *= offset[0] * edgeOfScreenClipFudgeFactor;
            this.hitOrigin.y *= offset[1] * edgeOfScreenClipFudgeFactor;
            Vector3.TransformCoordinatesToRef(this.hitOrigin, this.camera.getWorldMatrix(), this.ray.direction);
            this.ray.direction.subtractToRef(targetPosition, this.ray.direction);
            const distanceToObject = this.ray.direction.length();
            this.ray.length = distanceToObject;
            this.ray.direction.normalize();
            const hit = this.ctx.getScene().pickWithRay(this.ray);
            if (hit !== null && hit.pickedPoint !== null) {
                if (hit.distance < closestHitDistance) {
                    closestHitDistance = hit.distance;
                    this.closestHitPoint.copyFrom(hit.pickedPoint);
                    ({ closestHitPoint } = this);
                    this.closestHitOrigin.copyFrom(this.hitOrigin);
                }
            }
        });
        // eslint-disable-next-line
        if (closestHitPoint !== null) {
            this.camera.position.copyFrom(closestHitPoint);
            // Vector3.LerpToRef(
            //   this.camera.position,
            //   closestHitPoint,
            //   0.1,
            //   this.camera.position,
            // );
            lookRotation(targetPosition.subtract(closestHitPoint).normalize(), checkNotNull(this.camera.rotationQuaternion));
        }
    }
}
