import { cycle } from '../cycle';
import { shuffleInPlace } from '../shuffle';
const sayings = cycle(shuffleInPlace([
    'Graduated',
    'Monochromatic',
    'Asymmetrical',
    'Fragmented',
    'Precise', 'refined', 'regular', 'straight', 'systematic',
    'Quick', 'sketchy', 'uneven', 'irregular', 'vigorous',
]));
export class NonTalkingInteractor {
    spellings = sayings;
    // eslint-disable-next-line class-methods-use-this
    getGreeting() {
        return null;
    }
    // eslint-disable-next-line class-methods-use-this
    getSavedGreeting() {
        return null;
    }
    getSpell() {
        return this.spellings();
    }
    // eslint-disable-next-line class-methods-use-this
    getSaved() {
        return null;
    }
    // eslint-disable-next-line class-methods-use-this
    focusAfterSaving() {
        return false;
    }
}
