import '@babylonjs/core/Audio/audioSceneComponent';
import { Sound } from '@babylonjs/core/Audio/sound';
import { Engine } from '@babylonjs/core/Engines/engine';
import footsteps1Url from '../assets/audio/footsteps-1.mp3';
import gunClick1Url from '../assets/audio/gun-click-1.mp3';
import gunShot1Url from '../assets/audio/gun-shot-1.mp3';
import musicReverbUrl from '../assets/audio/music-reverb.mp3';
import musicUrl from '../assets/audio/music.mp3';
import spellingStartUrl from '../assets/audio/spelling-start.mp3';
import spellingSuccessUrl from '../assets/audio/spelling-success.mp3';
import spellingFailUrl from '../assets/audio/spelling-fail.mp3';
import xpUrl from '../assets/audio/xp.mp3';
import { Hub } from '../Hub';
import { Options } from '../options/Options';
import { TextToSpeech } from './TextToSpeech';
const FOOTSTEPS_VOL = 0.2;
const MUSIC_VOL = 0.5;
var Key;
(function (Key) {
    Key["Click"] = "click";
    Key["Footsteps1"] = "footsteps-1";
    Key["GunClick1"] = "gun-click-1";
    Key["GunShot1"] = "gun-shot-1";
    Key["Music"] = "music";
    Key["MusicReverb"] = "music-reverb";
    Key["SpellingFail"] = "spelling-fail";
    Key["SpellingStart"] = "spelling-start";
    Key["SpellingSucces"] = "spelling-success";
    Key["Xp"] = "xp";
})(Key || (Key = {}));
export class AudioManager {
    ctx;
    tts = new TextToSpeech();
    sounds = new Map();
    started = false;
    constructor(ctx) {
        this.ctx = ctx;
        Engine.audioEngine.useCustomUnlockedButton = Options.intro.get();
    }
    dispose() {
        this.tts.dispose();
    }
    async loadSound(key, url, options) {
        const assetsManager = this.ctx.getAssetsManager();
        const task = assetsManager.addBinaryFileTask('{key} task', url);
        const data = await new Promise((resolve, reject) => {
            task.onSuccess = () => {
                resolve(task.data);
            };
            task.onError = (_task, _message, exception) => {
                reject(exception);
            };
        });
        const sound = await new Promise((resolve) => {
            const newSoud = new Sound(key, data, this.ctx.getScene(), () => {
                resolve(newSoud);
            }, options);
        });
        this.sounds.set(key, sound);
    }
    getSound(key) {
        const sound = this.sounds.get(key);
        if (sound === undefined) {
            throw new Error(`No sounds for key ${key}`);
        }
        return sound;
    }
    load() {
        const promises = [];
        const load = (key, url, options) => {
            promises.push(this.loadSound(key, url, options));
        };
        load(Key.Footsteps1, footsteps1Url, {
            loop: true,
            playbackRate: 1.2,
            volume: 0,
        });
        load(Key.GunClick1, gunClick1Url, { volume: 0.5 });
        load(Key.GunShot1, gunShot1Url, { volume: 0.35 });
        load(Key.Music, musicUrl, { loop: true, volume: 0, autoplay: false });
        load(Key.MusicReverb, musicReverbUrl, {
            loop: true,
            volume: 0,
            autoplay: false,
        });
        load(Key.SpellingFail, spellingFailUrl, { volume: 0.3 });
        load(Key.SpellingStart, spellingStartUrl, { volume: 0.4 });
        load(Key.SpellingSucces, spellingSuccessUrl, {
            volume: 0.2,
            playbackRate: 2,
        });
        load(Key.Xp, xpUrl, { volume: 0.2 });
        return Promise.all(promises);
    }
    startGame() {
        this.started = true;
        this.ctx.getScene().audioListenerPositionProvider = () => (this.ctx.getPlayer().getPosition());
        const footsteps = this.getSound(Key.Footsteps1);
        footsteps.play();
        const music = this.getSound(Key.Music);
        music.play();
        const musicReverb = this.getSound(Key.MusicReverb);
        musicReverb.setVolume(MUSIC_VOL, 5);
        musicReverb.play();
        Hub.misstype.on(() => {
            const sound = this.getSound(Key.GunClick1);
            sound.setPlaybackRate(0.95 + 0.1 * Math.random());
            sound.play();
        });
        Hub.typedLetter.on(() => {
            const sound = this.getSound(Key.GunShot1);
            sound.setPlaybackRate(0.95 + 0.1 * Math.random());
            sound.play();
        });
        Hub.spell.on(() => {
            const sound = this.getSound(Key.SpellingStart);
            sound.setPlaybackRate(0.9 + 0.2 * Math.random());
            sound.play();
            music.setVolume(1.2 * MUSIC_VOL, 2);
            musicReverb.setVolume(0, 1);
        });
        Hub.spellingFinished.on((_word, success) => {
            musicReverb.setVolume(MUSIC_VOL, 0.5);
            music.setVolume(0, 0.5);
            if (success) {
                this.getSound(Key.SpellingSucces).play();
                return;
            }
            this.getSound(Key.SpellingFail).play();
        });
        Hub.gameOver.on(() => {
            music.setPlaybackRate(0.7);
            musicReverb.setPlaybackRate(0.7);
        });
        Hub.xp.on((xp) => {
            const sound = this.getSound(Key.Xp);
            let i = 0;
            const next = () => {
                const pr = 1 + 0.1 * i;
                sound.stop();
                sound.setPlaybackRate(pr);
                if (++i >= xp) {
                    sound.onEndedObservable.removeCallback(next);
                }
                sound.play();
            };
            sound.onEndedObservable.add(next);
            next();
        });
    }
    update() {
        if (!this.started) {
            return;
        }
        const footsteps = this.getSound(Key.Footsteps1);
        const speed = this.ctx.getPlayer().getXZSpeed();
        footsteps.setVolume(FOOTSTEPS_VOL * speed, 0.1);
    }
}
