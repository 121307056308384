import { jsxs as _jsxs } from "react/jsx-runtime";
import { Component } from 'react';
import { Hub } from '../Hub';
import './run-timer.scss';
export class RunTimer extends Component {
    handle = 0;
    componentDidMount() {
        this.handle = window.setInterval(() => { this.forceUpdate(); }, 100);
    }
    componentWillUnmount() {
        window.clearInterval(this.handle);
    }
    render() {
        const startedAt = Hub.startedAt.get();
        const endedAt = Hub.endedAt.get();
        const elapsed = (endedAt !== -1 ? endedAt : Date.now()) - startedAt;
        const s = Math.floor(elapsed / 1000);
        const ms = Math.round((elapsed % 1000) / 10);
        return (_jsxs("div", { className: "run-timer", children: [s, ".", ms.toString().padEnd(2, '0')] }, void 0));
    }
}
