import { Vector3 } from '@babylonjs/core/Maths/math.vector';
import { PhysicsImpostor } from '@babylonjs/core/Physics/physicsImpostor';
import { fixMeshScaling } from '../fixMeshScaling';
import { rootName } from '../rootName';
export const initBox = (ctx, mesh) => {
    const { parent } = mesh;
    let needsRotate = false;
    if (parent !== null) {
        if (parent.name === rootName) {
            needsRotate = true;
        }
    }
    mesh.setParent(null);
    fixMeshScaling(mesh);
    const physicsImpostor = new PhysicsImpostor(mesh, PhysicsImpostor.BoxImpostor, {
        mass: 0,
        friction: 1,
        restitution: 0.3,
    }, ctx.getScene());
    mesh.physicsImpostor = physicsImpostor;
    if (needsRotate) {
        mesh.rotate(Vector3.Forward(), Math.PI);
    }
};
