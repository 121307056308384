import '@babylonjs/core/Culling/ray';
import { Engine } from '@babylonjs/core/Engines/engine';
import { Vector3 } from '@babylonjs/core/Maths/math.vector';
import { AssetsManager } from '@babylonjs/core/Misc/assetsManager';
import '@babylonjs/core/Physics/physicsEngineComponent';
import { AmmoJSPlugin } from '@babylonjs/core/Physics/Plugins/ammoJSPlugin';
import { Scene } from '@babylonjs/core/scene';
import Ammo from 'ammojs-typed';
import { AudioManager } from './audio/AudioManager';
import { Context } from './Context';
import { Game } from './Game';
const makeEngine = (canvas) => {
    const { log } = console;
    // eslint-disable-next-line no-console
    console.log = () => { };
    try {
        return new Engine(canvas, true /* antialias */);
    }
    finally {
        // eslint-disable-next-line no-console
        console.log = log;
    }
};
export class GameManager {
    engine;
    scene;
    assetsManager;
    audioManager;
    game;
    constructor(canvas) {
        this.engine = makeEngine(canvas);
        this.scene = new Scene(this.engine);
        this.assetsManager = new AssetsManager(this.scene);
        const ctx = new Context({
            assetsManager: this.assetsManager,
            scene: this.scene,
        });
        this.audioManager = new AudioManager(ctx);
        ctx.setAudioManger(this.audioManager);
        this.game = new Game(ctx);
        this.assetsManager.useDefaultLoadingScreen = false;
    }
    dispose() {
        this.game.dispose();
        this.scene.dispose();
        this.engine.dispose();
    }
    async load() {
        this.game.preload();
        const physics = Ammo().then((ammo) => {
            this.scene.enablePhysics(new Vector3(0, -9.81 * 2, 0), new AmmoJSPlugin(true, ammo));
        });
        const promises = [
            physics,
            this.audioManager.load(),
        ];
        if (FDS_INSPECTOR) {
            promises.push(import('@babylonjs/core/Debug/debugLayer'), import('@babylonjs/inspector'));
        }
        // This must be last.
        promises.push(this.assetsManager.loadAsync());
        await Promise.all(promises);
        this.game.init();
    }
    run() {
        this.game.runRenderLoop();
    }
    startGame() {
        this.game.startGame();
    }
}
