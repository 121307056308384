import { Vector3 } from '@babylonjs/core/Maths/math.vector';
import { Hub } from '../Hub';
export class Target {
    ctx;
    mesh;
    wantsToBeVisible = false;
    constructor(
    // @ts-expect-error
    ctx, mesh) {
        this.ctx = ctx;
        this.mesh = mesh;
    }
    getPosition() {
        return this.mesh.position;
    }
    setPosition(position) {
        this.mesh.position.copyFrom(position);
    }
    setVisible(isVisible) {
        this.wantsToBeVisible = isVisible;
    }
    update() {
        this.mesh.isVisible = this.wantsToBeVisible && !Hub.spelling.get();
        this.mesh.rotate(Vector3.Up(), Math.PI / (32 * 2));
    }
}
