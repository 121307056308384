import { Option } from './Option';
export class IntegerOption extends Option {
    // eslint-disable-next-line class-methods-use-this
    decode(rawValue) {
        const value = parseInt(rawValue.trim(), 10);
        if (Number.isNaN(value)) {
            return null;
        }
        return [value];
    }
    encode() {
        return this.value.toString();
    }
}
