export class Option {
    storage;
    defaultValue;
    bGet = this.get.bind(this);
    bSet = this.set.bind(this);
    value;
    handlers = new Set();
    constructor(storage, defaultValue) {
        this.storage = storage;
        this.defaultValue = defaultValue;
        this.value = defaultValue;
        this.storage.observe((value) => {
            const decoded = this.decode(value);
            if (decoded !== null) {
                this.set(decoded[0]);
            }
        });
    }
    getStorage() {
        return this.storage;
    }
    get() {
        return this.value;
    }
    // eslint-disable-next-line class-methods-use-this
    equal(a, b) {
        return a === b;
    }
    set(value) {
        if (!this.equal(value, this.value)) {
            this.value = value;
            this.notifyChange();
        }
    }
    setDefault() {
        this.set(this.defaultValue);
    }
    load(params) {
        const rawValue = this.storage.load(params);
        if (rawValue === null) {
            return false;
        }
        const result = this.decode(rawValue);
        if (result === null) {
            return false;
        }
        [this.value] = result;
        return true;
    }
    save(params) {
        const rawValue = this.equal(this.value, this.defaultValue)
            ? null
            : this.encode();
        if (rawValue === null) {
            this.storage.remove(params);
            return;
        }
        this.storage.save(rawValue, params);
    }
    onChange(handler) {
        this.handlers.add(handler);
    }
    offChange(handler) {
        this.handlers.delete(handler);
    }
    notifyChange() {
        this.handlers.forEach((handler) => {
            handler(this);
        });
    }
}
