import { Color3, Color4 } from '@babylonjs/core/Maths/math.color';
import { Scene } from '@babylonjs/core/scene';
export const initFog = (ctx) => {
    const scene = ctx.getScene();
    scene.fogMode = Scene.FOGMODE_LINEAR;
    scene.fogColor = new Color3(0.0, 0.0, 0.0);
    scene.clearColor.copyFrom(Color4.FromColor3(scene.fogColor));
    scene.fogStart = 20;
    scene.fogEnd = 80;
};
