import { Storage } from './Storage';
export class LocalStorage extends Storage {
    loaded = false;
    loadedValue;
    getValue() {
        return localStorage.getItem(this.key);
    }
    setValue(value) {
        localStorage.setItem(this.key, value);
    }
    load() {
        this.loadedValue = this.getValue();
        this.loaded = true;
        return this.loadedValue;
    }
    save(value) {
        this.setValue(value);
    }
    remove() {
        localStorage.removeItem(this.key);
    }
    observe(notifyChange) {
        window.addEventListener('storage', () => {
            if (!this.loaded) {
                return;
            }
            const value = this.getValue();
            if (value !== null) {
                notifyChange(value);
            }
        });
    }
}
