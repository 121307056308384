import { Control } from './Control';
import { ControlsMode } from './ControlsMode';
export class Controls {
    controlsMode = ControlsMode.Keyboard;
    debug = new Control();
    up = new Control();
    down = new Control();
    left = new Control();
    right = new Control();
    interact = new Control();
    raise = new Control();
    raiseWater = new Control();
    respawn = new Control();
    resetJustPressed() {
        this.debug.resetJustPressed();
        this.up.resetJustPressed();
        this.down.resetJustPressed();
        this.left.resetJustPressed();
        this.right.resetJustPressed();
        this.interact.resetJustPressed();
        this.raise.resetJustPressed();
        this.respawn.resetJustPressed();
        this.raiseWater.resetJustPressed();
    }
    setControlsMode(controlsMode) {
        this.controlsMode = controlsMode;
    }
    getControlsMode() {
        return this.controlsMode;
    }
}
