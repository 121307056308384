export const cycle = (array) => {
    if (array.length === 0) {
        throw new Error('array cannot be empty');
    }
    let index = 0;
    return () => {
        const item = array[index];
        index = (index + 1) % array.length;
        return item;
    };
};
