import { jsxs as _jsxs } from "react/jsx-runtime";
import { Component } from 'react';
import { Hub } from '../Hub';
import './go.scss';
const mins = (m) => m * 60 * 1000;
const getRank = (duration) => {
    if (duration <= mins(3)) {
        return 'A';
    }
    if (duration <= mins(4)) {
        return 'B';
    }
    if (duration <= mins(5)) {
        return 'C';
    }
    if (duration <= mins(6)) {
        return 'D';
    }
    if (duration <= mins(7)) {
        return 'E';
    }
    return 'F';
};
export class GameOver extends Component {
    componentDidMount() {
        const startedAt = Hub.startedAt.get();
        let endedAt = Hub.endedAt.get();
        if (endedAt === -1) {
            endedAt = Date.now();
        }
        const duration = endedAt - startedAt;
        setTimeout(() => {
            Hub.say.emit(`Game over. Rank: ${getRank(duration)}`, { singmode: true });
        }, 5000);
    }
    render() {
        const startedAt = Hub.startedAt.get();
        let endedAt = Hub.endedAt.get();
        if (endedAt === -1) {
            endedAt = Date.now();
        }
        const duration = endedAt - startedAt;
        return (_jsxs("div", { className: "go", children: ["Rank: ", getRank(duration)] }, void 0));
    }
}
