import { Effect } from '@babylonjs/core/Materials/effect';
import { PostProcess } from '@babylonjs/core/PostProcesses/postProcess';
export const initPostProcess = (ctx) => {
    Effect.ShadersStore["customFragmentShader"] = `
    #ifdef GL_ES
        precision highp float;
    #endif

    // Samplers
    varying vec2 vUV;
    uniform sampler2D textureSampler;

    // Parameters
    uniform vec2 screenSize;
    uniform float threshold;

    void main(void) 
    {
        vec2 texelSize = vec2(1.0 / screenSize.x, 1.0 / screenSize.y);
        vec4 baseColor = texture2D(textureSampler, vUV);

            float bleed = texture2D(textureSampler, vUV + vec2(0.01, 0.0)).r;
                bleed += texture2D(textureSampler, vUV + vec2(0.02, 0.0)).r;
                bleed += texture2D(textureSampler, vUV + vec2(0.01, 0.01)).r;
                bleed += texture2D(textureSampler, vUV + vec2(0.02, 0.02)).r;
                bleed /= 12.0;

            vec4 rgba = baseColor + bleed;

        gl_FragColor = rgba;
    }
    `;
    const postProcess = new PostProcess("My custom post process", "custom", ["screenSize", "threshold"], null, 1, ctx.getCamera());
    postProcess.onApply = (effect) => {
        effect.setFloat2("screenSize", postProcess.width, postProcess.height);
        effect.setFloat("threshold", 0.30);
    };
};
