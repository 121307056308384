import { checkNotNull } from './checkNotNull';
import { checkNull } from './checkNull';
import { Controls } from './controls/Controls';
export class Context {
    assetsManager;
    scene;
    controls = new Controls();
    isDebugEnabled = false;
    audioManager = null;
    cameraOffset = null;
    cameraTarget = null;
    shadowGenerator = null;
    player = null;
    isGameOver = false;
    interactables = [];
    target = null;
    cameraController = null;
    constructor(init) {
        this.assetsManager = init.assetsManager;
        this.scene = init.scene;
    }
    getCamera() {
        return checkNotNull(this.getCameraController().getCamera());
    }
    getCameraController() {
        return checkNotNull(this.cameraController);
    }
    setCameraController(cameraController) {
        this.cameraController = cameraController;
    }
    getCameraOffset() {
        return checkNotNull(this.cameraOffset);
    }
    setCameraOffset(cameraOffset) {
        this.cameraOffset = cameraOffset;
    }
    getAssetsManager() {
        return this.assetsManager;
    }
    getAudioManager() {
        return checkNotNull(this.audioManager);
    }
    setAudioManger(audioManager) {
        checkNull(this.audioManager);
        this.audioManager = audioManager;
    }
    getCameraTarget() {
        return checkNotNull(this.cameraTarget);
    }
    setCameraTarget(cameraTarget) {
        this.cameraTarget = cameraTarget;
    }
    getControls() {
        return this.controls;
    }
    getEngine() {
        return this.scene.getEngine();
    }
    getPlayer() {
        return checkNotNull(this.player);
    }
    setPlayer(player) {
        this.player = player;
    }
    getScene() {
        return this.scene;
    }
    getShadowGenerator() {
        return checkNotNull(this.shadowGenerator);
    }
    setShadowGenerator(shadowGenerator) {
        this.shadowGenerator = shadowGenerator;
    }
    getIsDebugEnabled() {
        return this.isDebugEnabled;
    }
    setIsDebugEnabled(isDebugEnabled) {
        this.isDebugEnabled = isDebugEnabled;
    }
    toggleIsDebugEnabled() {
        this.isDebugEnabled = !this.isDebugEnabled;
    }
    getIsGameOver() {
        return this.isGameOver;
    }
    setIsGameOver(isGameOver) {
        this.isGameOver = isGameOver;
    }
    addInteractable(interactable) {
        this.interactables.push(interactable);
    }
    setTarget(target) {
        this.target = target;
    }
    getTarget() {
        return checkNotNull(this.target);
    }
}
