class Message {
    handlers = new Set();
    emit(...args) {
        this.handlers.forEach((handler) => { handler(...args); });
    }
    on(handler) {
        this.handlers.add(handler);
    }
    off(handler) {
        this.handlers.delete(handler);
    }
    watch(handler) {
        this.on(handler);
        return () => { this.off(handler); };
    }
}
class State {
    value;
    constructor(value) {
        this.value = value;
    }
    get() {
        return this.value;
    }
    set(value) {
        this.value = value;
    }
}
export const Hub = {
    // Messages
    caption: new Message(),
    gameOver: new Message(),
    misstype: new Message(),
    prompt: new Message(),
    said: new Message(),
    say: new Message(),
    spell: new Message(),
    spellingFinished: new Message(),
    typedLetter: new Message(),
    xp: new Message(),
    // State
    spelling: new State(false),
    startedAt: new State(-1),
    endedAt: new State(-1),
};
