import { Vector3 } from '@babylonjs/core/Maths/math.vector';
import { MeshBuilder } from '@babylonjs/core/Meshes/meshBuilder';
import { PhysicsImpostor } from '@babylonjs/core/Physics/physicsImpostor';
import { checkMesh } from '../checkMesh';
import { checkNotNull } from '../checkNotNull';
import { Player } from '../entities/Player';
import { fixMeshScaling } from '../fixMeshScaling';
import { Options } from '../options/Options';
export const initPlayer = (ctx) => {
    const walk = checkNotNull(ctx.getScene().getAnimationGroupByName('Weasel.Walk.001'));
    const talk = checkNotNull(ctx.getScene().getAnimationGroupByName('Weasel.Talk.001'));
    const startPoint = checkNotNull(ctx.getScene().getMeshByName(Options.start.get() ?? 'StartPoint.001'));
    startPoint.isVisible = false;
    startPoint.setParent(null);
    startPoint.rotate(Vector3.Forward(), Math.PI);
    fixMeshScaling(startPoint);
    const mesh = checkMesh(ctx.getScene().getMeshByName('WeaselMesh'));
    const armature = checkNotNull(ctx.getScene().getTransformNodeByName('WeaselArmature'));
    mesh.isPickable = false;
    const extents = mesh.getBoundingInfo().boundingBox.extendSize;
    const hitbox = MeshBuilder.CreateBox('hitbox', {
        width: extents.x * 2,
        height: extents.y * 2,
        depth: extents.z * 2,
    }, ctx.getScene());
    ctx.getShadowGenerator().addShadowCaster(mesh);
    fixMeshScaling(mesh);
    mesh.parent = hitbox;
    mesh.position.y -= 0.3;
    fixMeshScaling(armature);
    armature.parent = null;
    const skeleton = checkNotNull(ctx.getScene().getSkeletonByName('WeaselArmature'));
    skeleton.overrideMesh = null;
    const physicsImpostor = new PhysicsImpostor(hitbox, PhysicsImpostor.CapsuleImpostor, {
        mass: 1,
        friction: 0.2,
        restitution: 1,
    }, ctx.getScene());
    hitbox.physicsImpostor = physicsImpostor;
    hitbox.isVisible = false;
    ctx.setPlayer(new Player(ctx, hitbox, startPoint, walk, talk));
};
