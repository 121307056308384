import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Component } from 'react';
import { checkNotNull } from '../checkNotNull';
import { Hub } from '../Hub';
import { Caption } from './Caption';
import { GameOver } from './GameOver';
import { RunTimer } from './RunTimer';
import { Spell } from './Spell';
import { SpellTimer } from './SpellTimer';
import './ui.scss';
export class Ui extends Component {
    bHandleSpelt = this.handleSpelt.bind(this);
    bHandleTimerFinished = this.handleTimerFinished.bind(this);
    offMisstype = Hub.misstype.watch(() => {
        this.setState(prevState => ({ misstypes: prevState.misstypes + 1 }));
    });
    offSpell = Hub.spell.watch(this.setSpell.bind(this));
    state = {
        spell: null,
        misstypes: 0,
    };
    componentDidMount() {
        Hub.startedAt.set(Date.now());
        Hub.gameOver.on(() => { this.forceUpdate(); });
    }
    componentWillUnmount() {
        this.offSpell();
        this.offMisstype();
    }
    setSpell(spell) {
        Hub.spelling.set(spell !== null);
        this.setState({ spell, misstypes: 0 });
    }
    handleSpelt() {
        const spell = checkNotNull(this.state.spell);
        this.setSpell(null);
        Hub.spellingFinished.emit(spell, true);
    }
    handleTimerFinished() {
        const spell = checkNotNull(this.state.spell);
        this.setSpell(null);
        Hub.spellingFinished.emit(spell, false);
    }
    renderSpellTop() {
        const { spell, misstypes } = this.state;
        return spell === null ? null : (_jsx(SpellTimer, { duration: 20_000, misstypes: misstypes, onFinished: this.bHandleTimerFinished }, void 0));
    }
    renderRunTimer() {
        const { spell } = this.state;
        return spell !== null ? null : (_jsxs("div", { className: "ui__run-bar", children: [Hub.endedAt.get() !== -1 ? _jsx(GameOver, {}, void 0) : null, _jsx(RunTimer, {}, void 0)] }, void 0));
    }
    renderSpellBottom() {
        const { spell } = this.state;
        return spell === null ? null : (_jsx(Spell, { text: spell, onSpelt: this.bHandleSpelt }, void 0));
    }
    render() {
        return (_jsxs("div", { className: "ui", children: [this.renderSpellTop(), this.renderRunTimer(), this.renderSpellBottom(), _jsx(Caption, {}, void 0), Hub.endedAt.get() === -1 ? null : _jsx(GameOver, {}, void 0)] }, void 0));
    }
}
