import { Vector3 } from '@babylonjs/core/Maths/math.vector';
import { checkTransformNode } from '../checkTransformNode';
import { Hub } from '../Hub';
import { Leveller } from '../level/Leveller';
import { parseDistance } from '../parseDistance';
import { parseHealth } from '../parseHealth';
import { parseLevel } from '../parseLevel';
import { ItemParticles } from '../particles/ItemParticles';
var InteractableState;
(function (InteractableState) {
    InteractableState[InteractableState["Unstable"] = 0] = "Unstable";
    InteractableState[InteractableState["SpeltNeedsToThank"] = 1] = "SpeltNeedsToThank";
    InteractableState[InteractableState["Spelt"] = 2] = "Spelt";
})(InteractableState || (InteractableState = {}));
const stateAnimWeightTime = 1000;
export class Interactable {
    ctx;
    mesh;
    interactor;
    idle;
    unstable;
    savedSpot;
    topPosition = new Vector3();
    wasInRange = false;
    wasTalking = false;
    minDistance;
    health = 2;
    state = InteractableState.Unstable;
    stateLastChangedAt = Date.now();
    saved = false;
    particles;
    leveller;
    collideMesh;
    moveToTargetLocation = false;
    startPosition;
    thankedWords = null;
    pitch = 100 + 20 * Math.random();
    baseHealth;
    constructor(ctx, mesh, interactor, idle, unstable, savedSpot = null) {
        this.ctx = ctx;
        this.mesh = mesh;
        this.interactor = interactor;
        this.idle = idle;
        this.unstable = unstable;
        this.savedSpot = savedSpot;
        this.particles = new ItemParticles(ctx, mesh);
        this.leveller = new Leveller(this.ctx, this.mesh);
        const level = parseLevel(this.mesh.name) ?? 2;
        this.baseHealth = parseHealth(this.mesh.name) ?? 1;
        this.leveller.setLevel(level);
        this.collideMesh = mesh;
        this.startPosition = this.getBasePosition().clone();
        Hub.said.on((words) => {
            if (words === this.thankedWords && this.saved) {
                this.moveToTargetLocation = true;
                if (this.mesh.name.includes('ManHead')) {
                    Hub.endedAt.set(Date.now());
                    Hub.gameOver.emit();
                }
            }
        });
        this.minDistance = parseDistance(this.mesh.name) ?? 8;
    }
    setCollideMesh(mesh) {
        this.collideMesh = mesh;
    }
    getMesh() {
        return this.mesh;
    }
    getPosition() {
        return this.mesh.position;
    }
    getBaseHealth() {
        return this.baseHealth;
    }
    getTopPosition() {
        this.topPosition.copyFrom(this.mesh.absolutePosition);
        this.topPosition.y =
            this.mesh.getBoundingInfo().boundingBox.maximumWorld.y + 3;
        return this.topPosition;
    }
    getIsTalking() {
        return this.wasTalking;
    }
    setIsTalking(wasTalking) {
        this.wasTalking = wasTalking;
    }
    setWasInRange(wasInRange) {
        this.wasInRange = wasInRange;
    }
    getWasInRange() {
        return this.wasInRange;
    }
    getInteractor() {
        return this.interactor;
    }
    getMinDistance() {
        return this.minDistance;
    }
    getSaved() {
        return this.saved;
    }
    getPitch() {
        return this.pitch;
    }
    getLevel() {
        return this.leveller.getCurrentLevel();
    }
    setLevel(level) {
        this.leveller.setLevel(level);
    }
    getHealth() {
        return this.health;
    }
    setHealth(health) {
        this.health = health;
    }
    thank() {
        const savedSaying = this.interactor.getSaved();
        this.thankedWords = savedSaying;
        if (savedSaying !== null) {
            Hub.say.emit(savedSaying, { pitch: this.pitch });
            Hub.caption.emit(savedSaying, 4000);
        }
        else {
            this.moveToTargetLocation = true;
        }
    }
    getBaseNode() {
        if (this.mesh.skeleton !== null) {
            const node = checkTransformNode(this.mesh.skeleton.bones[0]._linkedTransformNode);
            return node;
        }
        return this.mesh;
    }
    getBasePosition() {
        return this.getBaseNode().position;
    }
    getAbsoluteBasePosition() {
        return this.getBaseNode().absolutePosition;
    }
    moveToTarget() {
        if (this.savedSpot === null || !this.moveToTargetLocation) {
            return;
        }
        const newPosition = this.savedSpot.position;
        let target = this.mesh.position;
        if (this.mesh.skeleton !== null) {
            const node = checkTransformNode(this.mesh.skeleton.bones[0]._linkedTransformNode);
            const newPos = this.startPosition.add(newPosition);
            const lerpAmount = 0.015;
            Vector3.LerpToRef(node.position, newPos, lerpAmount, node.position);
            this.collideMesh.position.copyFrom(node.absolutePosition);
            this.mesh.position.copyFrom(node.absolutePosition);
            this.particles.setPosition(this.mesh.position);
        }
        else {
            target.copyFrom(newPosition);
        }
    }
    setSpelt() {
        this.saved = true;
        this.state = InteractableState.SpeltNeedsToThank;
        this.stateLastChangedAt = Date.now();
    }
    update() {
        const { unstable } = this;
        const now = Date.now();
        this.leveller.setVisible(this.wasInRange && !this.saved && !Hub.spelling.get());
        this.leveller.update();
        this.leveller.setColor(this.getLevel() <= this.ctx.getPlayer().getLevel()
            ? '#55cc88' : '#ff9999');
        let animation;
        if (this.saved) {
            this.particles.startSaved();
        }
        else {
            this.particles.stopSaved();
        }
        switch (this.state) {
            case InteractableState.Unstable:
                if (unstable === null) {
                    animation = this.idle;
                }
                else {
                    if (this.idle !== null) {
                        this.idle.stop();
                    }
                    animation = unstable;
                }
                break;
            case InteractableState.SpeltNeedsToThank:
            case InteractableState.Spelt:
                this.moveToTarget();
                const progress = Math.min((now - this.stateLastChangedAt) / stateAnimWeightTime, 1);
                if (unstable !== null) {
                    unstable.setWeightForAllAnimatables(1 - progress);
                }
                if (progress === 1
                    && this.state === InteractableState.SpeltNeedsToThank) {
                    this.thank();
                    this.state = InteractableState.Spelt;
                }
                if (this.idle !== null) {
                    this.idle.setWeightForAllAnimatables(Math.max(progress, 0.1));
                }
                animation = this.idle;
                break;
            default:
                throw new Error();
        }
        if (animation === null) {
            return;
        }
        if (!animation.isPlaying) {
            animation.play(true);
        }
        animation.speedRatio = this.wasTalking ? 1 : 0.4;
    }
}
