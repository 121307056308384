import { Option } from './Option';
const trueValues = new Set([
    '',
    'true',
    'y',
    'yes',
]);
const falseValues = new Set([
    'false',
    'n',
    'no',
]);
export class BooleanOption extends Option {
    bToggle = this.toggle.bind(this);
    // eslint-disable-next-line class-methods-use-this
    decode(rawValue) {
        const value = rawValue.trim().toLowerCase();
        if (trueValues.has(value)) {
            return [true];
        }
        if (falseValues.has(value)) {
            return [false];
        }
        return null;
    }
    encode() {
        return this.value ? 'y' : 'n';
    }
    toggle() {
        this.set(!this.get());
    }
}
