import '@babylonjs/core/Lights/Shadows/shadowGeneratorSceneComponent';
import { HemisphericLight } from '@babylonjs/core/Lights/hemisphericLight';
import { ShadowGenerator } from '@babylonjs/core/Lights/Shadows/shadowGenerator';
import { Vector3 } from '@babylonjs/core/Maths/math.vector';
import { checkDirectionalLight } from '../checkDirectionalLight';
export const initLights = (ctx) => {
    new HemisphericLight('ambient', new Vector3(0, 1, 1), ctx.getScene());
    const sun = checkDirectionalLight(ctx.getScene().getLightByName('Sun'));
    sun.autoCalcShadowZBounds = true;
    const shadowGenerator = new ShadowGenerator(1024 * 2, sun);
    shadowGenerator.bias = 0.005; // Value found by tweaking acne in inspector
    ctx.setShadowGenerator(shadowGenerator);
};
