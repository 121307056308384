export class Control {
    pressed = false;
    justPressed = false;
    isPressed() {
        return this.pressed;
    }
    isJustPressed() {
        return this.justPressed;
    }
    press() {
        if (!this.pressed) {
            this.justPressed = true;
        }
        this.pressed = true;
    }
    release() {
        this.pressed = false;
    }
    resetJustPressed() {
        this.justPressed = false;
    }
}
