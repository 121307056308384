import { Vector3 } from '@babylonjs/core/Maths/math.vector';
import { MeshBuilder } from '@babylonjs/core/Meshes/meshBuilder';
import { PhysicsImpostor } from '@babylonjs/core/Physics/physicsImpostor';
export const initMeshCollidableFromBoundingBox = (ctx, mesh) => {
    const bbox = mesh.getBoundingInfo().boundingBox;
    let box = MeshBuilder.CreateBox('bbox', {
        width: bbox.maximum.x - bbox.minimum.x,
        height: bbox.maximum.y - bbox.minimum.y,
        depth: bbox.maximum.z - bbox.minimum.z,
    });
    box.position.copyFrom(mesh.position);
    box.rotation.copyFrom(mesh.rotation);
    box.rotate(Vector3.Forward(), Math.PI);
    box.isVisible = false;
    mesh.physicsImpostor = new PhysicsImpostor(box, PhysicsImpostor.BoxImpostor, {
        mass: 0,
        friction: 1,
        restitution: 0,
    }, ctx.getScene());
    return box;
};
