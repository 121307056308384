import { Mesh } from '@babylonjs/core/Meshes/mesh';
import { Hub } from '../Hub';
import { LevelLabel } from './LevelLabel';
export class Leveller {
    parentMesh;
    currentLevel = 1;
    currentExperiencePoints = 0;
    addXpLabel;
    label;
    lastXpAt = Date.now();
    holder;
    isVisible = true;
    constructor(ctx, parentMesh) {
        this.parentMesh = parentMesh;
        this.label = new LevelLabel(ctx, 'label');
        this.holder = new Mesh('mesh', ctx.getScene());
        const mesh = this.label.getMesh();
        mesh.parent = this.holder;
        mesh.isPickable = false;
        mesh.position.y = 0.25;
        this.label.setColor('#00ffff');
        mesh.scaling.setAll(0.4);
        this.addXpLabel = new LevelLabel(ctx, 'xp');
        const xpMesh = this.addXpLabel.getMesh();
        xpMesh.isPickable = false;
        xpMesh.position.y = mesh.position.y + 1;
        this.addXpLabel.setColor('#ffcc00');
        xpMesh.scaling.setAll(0.4);
        xpMesh.parent = this.holder;
    }
    getCurrentLevel() {
        return this.currentLevel;
    }
    setVisible(isVisible) {
        this.isVisible = isVisible;
    }
    setLevel(level) {
        this.currentLevel = level;
        this.label.renderText(`Level ${this.currentLevel}`);
        this.currentExperiencePoints = level * level;
    }
    setColor(color) {
        this.label.setColor(color);
    }
    addExperience(xp) {
        this.currentExperiencePoints += xp;
        this.lastXpAt = Date.now();
        let level = Math.floor(Math.sqrt(this.currentExperiencePoints));
        this.setLevel(level);
        this.addXpLabel.renderText(`+${xp}XP`);
        Hub.xp.emit(xp);
    }
    update() {
        const now = Date.now();
        this.addXpLabel.getMesh().isVisible = this.isVisible && (now - this.lastXpAt < 3000);
        this.label.getMesh().isVisible = this.isVisible;
        this.holder.position.copyFrom(this.parentMesh.absolutePosition);
        this.holder.position.y =
            this.parentMesh.getBoundingInfo().boundingBox.maximumWorld.y + 1;
    }
}
