import { checkDefined } from '../checkDefined';
import { nullIfUndefined } from '../nullIfUndefined';
import { Storage } from './Storage';
export class UrlStorage extends Storage {
    loadKey;
    constructor(key) {
        super(key);
        this.loadKey = key.toLowerCase();
    }
    load(params) {
        return nullIfUndefined(checkDefined(params).get(this.loadKey));
    }
    save(value, params) {
        checkDefined(params).set(this.key, value);
    }
    remove(params) {
        checkDefined(params).delete(this.key);
    }
    // eslint-disable-next-line class-methods-use-this
    observe() {
        // No-op at the moment.
    }
}
