import { cycle } from '../cycle';
import { shuffleInPlace } from '../shuffle';
const allGreetings = cycle(shuffleInPlace([
    'Hello',
    'Hi',
]));
const allSavedGreetings = cycle(shuffleInPlace([
    'Hello', 'Hi again', 'Hey pal', 'Yo bud',
]));
const allSpellings = cycle(shuffleInPlace([
    'Much brighter than I expected',
    'What do you think about everything?',
    'I see, I see',
]));
const allSaved = cycle(shuffleInPlace([
    'Thaaank you so muuuch',
    'I am ascending',
]));
export class TalkingInteractor {
    greetings = allGreetings;
    savedGreetings = allSavedGreetings;
    spellings = allSpellings;
    saved = allSaved;
    constructor(options) {
        if (options !== undefined) {
            if (options.greetings !== undefined) {
                this.greetings = options.greetings;
            }
            if (options.savedGreetings !== undefined) {
                this.savedGreetings = options.savedGreetings;
            }
            if (options.spellings !== undefined) {
                this.spellings = options.spellings;
            }
            if (options.saved !== undefined) {
                this.saved = options.saved;
            }
        }
    }
    getGreeting() {
        return this.greetings();
    }
    getSavedGreeting() {
        return this.savedGreetings();
    }
    getSpell() {
        return this.spellings();
    }
    getSaved() {
        return this.saved();
    }
    // eslint-disable-next-line class-methods-use-this
    focusAfterSaving() {
        return true;
    }
}
