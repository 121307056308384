import { Quaternion, Vector3 } from '@babylonjs/core/Maths/math.vector';
import { checkNotNull } from '../checkNotNull';
import { ControlsMode } from '../controls/ControlsMode';
import { Hub } from '../Hub';
import { Leveller } from '../level/Leveller';
import { lookRotation } from '../lookRotation';
import { Options } from '../options/Options';
const epsilon = 0.1;
var PlayerState;
(function (PlayerState) {
    PlayerState[PlayerState["Moving"] = 0] = "Moving";
    PlayerState[PlayerState["GunShot"] = 1] = "GunShot";
    PlayerState[PlayerState["Spelt"] = 2] = "Spelt";
})(PlayerState || (PlayerState = {}));
export class Player {
    ctx;
    mesh;
    startPoint;
    walkAnimation;
    talkAnimation;
    static MAX_SPEED = 15;
    nextLook = new Vector3();
    velocityDelta = new Vector3();
    isControlling = false;
    isLowering = false;
    isRaising = false;
    isRaisingWater = false;
    xzSpeed = 0;
    damping = 0.7;
    physicsImpostor;
    targetQuaternion = new Quaternion();
    state = PlayerState.Moving;
    stateChangedAt = Date.now();
    speltWord = '';
    currentInteractable = null;
    topPosition = new Vector3();
    leveller;
    gameOver = false;
    constructor(ctx, mesh, startPoint, walkAnimation, talkAnimation) {
        this.ctx = ctx;
        this.mesh = mesh;
        this.startPoint = startPoint;
        this.walkAnimation = walkAnimation;
        this.talkAnimation = talkAnimation;
        this.physicsImpostor = checkNotNull(mesh.physicsImpostor);
        this.respawn();
        Hub.said.on((word) => {
            if (this.speltWord === word && this.state === PlayerState.Spelt) {
                this.changeState(PlayerState.Moving);
                if (this.currentInteractable !== null) {
                    this.currentInteractable.setSpelt();
                    this.leveller.addExperience(this.currentInteractable.getLevel() * 10);
                }
            }
        });
        Hub.gameOver.on(() => {
            this.gameOver = true;
        });
        Hub.spellingFinished.on((word, success) => {
            if (success) {
                if (this.currentInteractable !== null) {
                    this.currentInteractable.setHealth(this.currentInteractable.getHealth() - 1);
                    if (this.currentInteractable.getHealth() > 0) {
                        Hub.spell.emit(this.currentInteractable.getInteractor().getSpell());
                    }
                    else {
                        this.speltWord = word;
                        this.changeState(PlayerState.Spelt);
                    }
                }
                return;
            }
            this.changeState(PlayerState.Moving);
            if (this.currentInteractable !== null) {
                this.currentInteractable.setIsTalking(false);
            }
            this.currentInteractable = null;
        });
        Hub.typedLetter.on(() => {
            this.changeState(PlayerState.GunShot);
        });
        this.leveller = new Leveller(ctx, mesh);
        this.leveller.setLevel(Options.level.get());
    }
    changeState(nextState) {
        if (this.state === nextState) {
            return;
        }
        this.stateChangedAt = Date.now();
        this.state = nextState;
    }
    updateDeltaFromKeyboardControls() {
        const delta = this.velocityDelta;
        const controls = this.ctx.getControls();
        if (controls.up.isPressed()) {
            delta.z += 1;
        }
        if (controls.down.isPressed()) {
            delta.z -= 1;
        }
        if (controls.left.isPressed()) {
            delta.x += 1;
        }
        if (controls.right.isPressed()) {
            delta.x -= 1;
        }
        if (controls.respawn.isPressed()) {
            this.respawn();
        }
    }
    updateVelocityDelta() {
        this.velocityDelta.setAll(0);
        if (!Hub.spelling.get() && this.state === PlayerState.Moving) {
            switch (this.ctx.getControls().getControlsMode()) {
                case ControlsMode.Keyboard:
                    this.updateDeltaFromKeyboardControls();
                    break;
                default:
                    throw new Error('Unknown controls mode');
            }
        }
        this.isControlling = this.velocityDelta.length() > 0;
    }
    getPosition() {
        return this.mesh.position;
    }
    getTopPosition() {
        this.topPosition.copyFrom(this.mesh.absolutePosition);
        this.topPosition.y +=
            this.mesh.getBoundingInfo().boundingBox.extendSize.y;
        return this.topPosition;
    }
    getLevel() {
        return this.leveller.getCurrentLevel();
    }
    getLinearVelocity() {
        return checkNotNull(this.physicsImpostor.getLinearVelocity());
    }
    getXZSpeed() {
        return this.xzSpeed;
    }
    getPhysicsImpostor() {
        return this.physicsImpostor;
    }
    setCurrentInteractable(interactable) {
        this.currentInteractable = interactable;
    }
    getCurrentInteractable() {
        return this.currentInteractable;
    }
    updateVelocity() {
        this.updateVelocityDelta();
        this.velocityDelta.scaleInPlace(1);
        const camera = checkNotNull(this.ctx.getScene().activeCamera);
        const forward = camera.getForwardRay().direction.clone();
        forward.y = 0;
        forward.normalize();
        const right = Vector3.Cross(forward, Vector3.Up());
        const velocity = this.getLinearVelocity();
        forward.scaleInPlace(this.velocityDelta.z);
        right.scaleInPlace(this.velocityDelta.x);
        velocity.addInPlace(forward).addInPlace(right);
        const { y } = velocity;
        velocity.y = 0;
        const speed = velocity.length();
        if (speed > Player.MAX_SPEED) {
            velocity.normalize().scaleInPlace(Player.MAX_SPEED);
            this.xzSpeed = 1;
        }
        else {
            this.xzSpeed = speed / Player.MAX_SPEED;
        }
        if (!this.isControlling) {
            velocity.scaleInPlace(this.damping);
        }
        if (this.isControlling || y < 0) {
            velocity.y = y;
        }
        this.physicsImpostor.setLinearVelocity(velocity);
    }
    respawn() {
        this.mesh.position.copyFrom(this.startPoint.position);
        checkNotNull(this.mesh.rotationQuaternion).copyFrom(checkNotNull(this.startPoint.rotationQuaternion));
        this.physicsImpostor.setLinearVelocity(Vector3.Zero());
        this.physicsImpostor.setAngularVelocity(Vector3.Zero());
    }
    getIsLowering() {
        return this.isLowering;
    }
    getIsRaising() {
        return this.isRaising;
    }
    getIsControlling() {
        return this.isControlling;
    }
    getIsRaisingWater() {
        return this.isRaisingWater && Options.pour.get();
    }
    getNextLook() {
        return this.nextLook;
    }
    update() {
        const now = Date.now();
        const timeSinceStateChange = now - this.stateChangedAt;
        if (this.gameOver) {
            this.leveller.setLevel(1000000);
            return;
        }
        this.leveller.update();
        this.leveller.setVisible(!Hub.spelling.get());
        switch (this.state) {
            case PlayerState.GunShot:
                if (timeSinceStateChange > 100) {
                    this.changeState(PlayerState.Moving);
                }
                break;
            default:
                break;
        }
        this.physicsImpostor.setAngularVelocity(Vector3.Zero());
        const controls = this.ctx.getControls();
        this.isLowering = controls.interact.isPressed();
        this.isRaising = controls.raise.isPressed();
        this.isRaisingWater = controls.raiseWater.isPressed();
        this.updateVelocity();
        const velocity = checkNotNull(this.physicsImpostor.getLinearVelocity());
        velocity.y = 0;
        if (velocity.length() > epsilon) {
            const forward = Player.MAX_SPEED / 2;
            velocity.normalize().scaleInPlace(forward);
            const targetLook = this.mesh.position.add(velocity);
            this.nextLook.copyFrom(targetLook);
            const q = this.mesh.rotationQuaternion;
            if (q !== null && velocity.length() > epsilon) {
                lookRotation(velocity, this.targetQuaternion);
                Quaternion.SlerpToRef(q, this.targetQuaternion, 0.95, q);
            }
        }
        if (this.state !== PlayerState.Spelt) {
            Vector3.LerpToRef(this.nextLook, this.getPosition(), 0.2, this.nextLook);
        }
        if (this.mesh.position.y < -30) {
            this.respawn();
        }
        if (this.state !== PlayerState.Spelt) {
            if (!this.walkAnimation.isPlaying) {
                this.walkAnimation.play();
            }
            this.talkAnimation.stop();
            let speedRatio = velocity.length() / Player.MAX_SPEED * 4;
            if (this.state === PlayerState.GunShot) {
                speedRatio = 4;
            }
            this.walkAnimation.speedRatio = speedRatio;
        }
        else {
            if (!this.talkAnimation.isPlaying) {
                this.talkAnimation.play();
            }
            this.walkAnimation.stop();
        }
    }
}
