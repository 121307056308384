import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Component, createRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { Engine } from '@babylonjs/core/Engines/engine';
import { checkNotNull } from '../checkNotNull';
import { GameManager } from '../GameManager';
import { Options } from '../options/Options';
import './game.scss';
import './global.scss';
import { Intro } from './Intro';
import { Ui } from './Ui';
export class App extends Component {
    bHandleAudioEnded = this.handleAudioEnded.bind(this);
    bHandleExited = this.handleExited.bind(this);
    classNames = { exitActive: 'intro--exit-active' };
    canvasRef = createRef();
    optGameManager = null;
    mounted = false;
    state = {
        introComplete: false,
        introRender: true,
        loaded: false,
    };
    componentDidMount() {
        this.mounted = true;
        this.optGameManager = new GameManager(checkNotNull(this.canvasRef.current));
        this.optGameManager.load().then(this.handleLoad.bind(this));
    }
    componentWillUnmount() {
        this.mounted = false;
        this.getGameManager().dispose();
        this.optGameManager = null;
    }
    handleAudioEnded() {
        Engine.audioEngine.unlock();
        this.setState({ introComplete: true });
        this.getGameManager().run();
    }
    handleExited() {
        this.setState({ introRender: false });
        this.getGameManager().startGame();
    }
    handleLoad() {
        if (!this.mounted) {
            return;
        }
        if (Options.intro.get()) {
            this.setState({ loaded: true });
            return;
        }
        this.setState({ loaded: true, introComplete: true });
        const gameManager = this.getGameManager();
        gameManager.run();
    }
    getGameManager() {
        return checkNotNull(this.optGameManager);
    }
    renderIntro() {
        const { introRender } = this.state;
        if (!introRender) {
            return null;
        }
        const { introComplete, loaded } = this.state;
        return (_jsx(CSSTransition, { in: !introComplete, timeout: 2000, classNames: this.classNames, onExited: this.bHandleExited, children: _jsx(Intro, { disabled: !loaded || introComplete || !Options.intro.get(), onAudioEnded: this.bHandleAudioEnded }, void 0) }, void 0));
    }
    renderUi() {
        const { introComplete } = this.state;
        return introComplete ? _jsx(Ui, {}, void 0) : null;
    }
    render() {
        return (_jsxs(_Fragment, { children: [_jsx("canvas", { className: "game", ref: this.canvasRef }, void 0), this.renderUi(), this.renderIntro()] }, void 0));
    }
}
