import { Matrix, Quaternion, Vector3 } from '@babylonjs/core/Maths/math.vector';
const up = new Vector3(0, 1, 0);
const zero = new Vector3();
const result = new Matrix();
export const lookRotation = (pos, ref) => {
    result.reset();
    zero.set(0, 0, 0);
    Matrix.LookAtLHToRef(zero, pos, up, result);
    result.invert();
    return Quaternion.FromRotationMatrixToRef(result, ref);
};
