import { BooleanOption } from './BooleanOption';
import { IntegerOption } from './IntegerOption';
import { Key as K } from './Key';
import { LocalStorage } from './LocalStorage';
import { NullableStringOption } from './NullableStringOption';
import { UrlStorage } from './UrlStorage';
const options = [];
const push = (option) => {
    options.push(option);
    return option;
};
// Local storage boolean
// const lb = (key: K, defaultValue = false) => (
//   push(new BooleanOption(new LocalStorage(key), defaultValue))
// );
// Local storage nullable string
// const lns = (key: K) => (
//   push(new NullableStringOption(new LocalStorage(key)))
// );
// URL storage boolean
const ub = (key, defaultValue = false) => (push(new BooleanOption(new UrlStorage(key), defaultValue)));
// URL storage nullable string
const uns = (key) => (push(new NullableStringOption(new UrlStorage(key))));
// URL storage integer
const ui = (key, defaultValue = 1) => (push(new IntegerOption(new UrlStorage(key), defaultValue)));
export const Options = {
    cheat: ub(K.Cheat),
    instructions: ub(K.Intructions, true),
    intro: ub(K.Intro, true),
    level: ui(K.Level, 1),
    mouse: ub(K.Mouse, false),
    music: ub(K.Music, true),
    mute: ub(K.Mute),
    pour: ub(K.Pour),
    start: uns(K.Start),
    water: ub(K.Water, true),
};
const saveUrl = (save) => {
    const nextUrl = new URL(document.URL);
    save(nextUrl.searchParams);
    window.history.replaceState(null, '', nextUrl.toString());
};
const handleChange = (option) => {
    const storage = option.getStorage();
    if (storage instanceof LocalStorage) {
        option.save();
        return;
    }
    if (storage instanceof UrlStorage) {
        saveUrl((params) => { option.save(params); });
        return;
    }
    throw new Error('Unhandled storage type');
};
(() => {
    {
        const url = new URL(document.URL);
        const params = new Map();
        url.searchParams.forEach((value, key) => {
            params.set(key.trim().toLowerCase(), value);
        });
        options.forEach((option) => { option.load(params); });
    }
    saveUrl((params) => {
        params.forEach((name) => { params.delete(name); });
        options.forEach((option) => {
            if (option.getStorage() instanceof UrlStorage) {
                option.save(params);
            }
        });
    });
    options.forEach((option) => { option.onChange(handleChange); });
})();
