import { jsx as _jsx } from "react/jsx-runtime";
import { Component } from 'react';
import { Hub } from '../Hub';
import './spell.scss';
import { LetterState, SpellLetter } from './SpellLetter';
const typeable = new Set('abcdefghijklmnopqrstuvwxyz ');
const SKIP = 'skip';
const keymap = new Map([
    ['space', ' '],
]);
if (!FDS_PRODUCTION) {
    keymap.set('super', SKIP);
}
export class Spell extends Component {
    bHandleKeyDown = this.handleKeyDown.bind(this);
    state = {
        index: 0,
    };
    componentDidMount() {
        window.addEventListener('keydown', this.bHandleKeyDown, { passive: true });
    }
    componentWillUnmount() {
        window.removeEventListener('keydown', this.bHandleKeyDown);
    }
    handleKeyDown({ key }) {
        key = key.toLowerCase();
        key = keymap.get(key) ?? key;
        const { text } = this.props;
        let { index } = this.state;
        if (index === text.length) {
            return;
        }
        if (key === SKIP) {
            index = text.length;
        }
        else if (!typeable.has(key)) {
            return;
        }
        else {
            const letter = text.charAt(index).toLowerCase();
            if (key.toLowerCase() !== letter) {
                Hub.misstype.emit();
                return;
            }
            while (index < text.length) {
                if (typeable.has(text.charAt(++index).toLowerCase())) {
                    break;
                }
            }
        }
        this.setState({ index });
        Hub.typedLetter.emit();
        if (index === text.length) {
            setTimeout(() => { Hub.say.emit(text); }, 1000);
            this.props.onSpelt();
        }
    }
    render() {
        const { text } = this.props;
        const { index } = this.state;
        const letters = Array.from(text).map((letter, i) => {
            let state;
            if (i < index) {
                state = LetterState.Typed;
            }
            else if (i === index) {
                state = LetterState.Next;
            }
            else {
                state = LetterState.Untyped;
            }
            return _jsx(SpellLetter, { letter: letter, state: state }, i);
        });
        return (_jsx("div", { className: "spell", children: letters }, void 0));
    }
}
