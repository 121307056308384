import { jsx as _jsx } from "react/jsx-runtime";
import { render } from 'react-dom';
import { checkNotNull } from './checkNotNull';
import { App } from './ui/App';
// const main = async () => {
//   const gameManager = new GameManager();
//   const intro = new Intro();
//   await gameManager.load();
//   intro.enable();
//   gameManager.run();
//   await intro.wait();
//   intro.dispose();
//   gameManager.startGame();
// };
const main = () => {
    render(_jsx(App, {}, void 0), checkNotNull(document.getElementById('app')));
};
if (document.readyState !== 'loading') {
    if (FDS_PRODUCTION) {
        main();
    }
    else {
        window.setTimeout(main, 0);
    }
}
else {
    window.addEventListener('DOMContentLoaded', main, {
        once: true,
        passive: true,
    });
}
