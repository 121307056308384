import { Vector3 } from '@babylonjs/core/Maths/math.vector';
import { checkFreeCamera } from '../checkFreeCamera';
import { CameraController } from '../entities/CameraController';
import { Options } from '../options/Options';
export const initCamera = (ctx) => {
    const scene = ctx.getScene();
    const camera = checkFreeCamera(scene.getCameraByName('Camera'));
    scene.activeCamera = camera;
    if (Options.mouse.get()) {
        camera.attachControl(true);
    }
    camera.computeWorldMatrix();
    const m = camera.getWorldMatrix();
    const p = camera.position;
    const position = Vector3.TransformCoordinates(p, m);
    const rotation = camera.absoluteRotation;
    camera.parent = null;
    camera.position = position;
    camera.rotation = rotation.toEulerAngles();
    ctx.setCameraOffset(position);
    ctx.setCameraController(new CameraController(ctx, camera));
};
