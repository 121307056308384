import { jsx as _jsx } from "react/jsx-runtime";
import classNames from 'classnames';
import { Component } from 'react';
export var LetterState;
(function (LetterState) {
    LetterState[LetterState["Untyped"] = 0] = "Untyped";
    LetterState[LetterState["Next"] = 1] = "Next";
    LetterState[LetterState["Typed"] = 2] = "Typed";
})(LetterState || (LetterState = {}));
export class SpellLetter extends Component {
    static defaultProps = {
        state: LetterState.Untyped,
    };
    render() {
        const { letter, state } = this.props;
        const className = classNames('spell__kbd', {
            'spell__kbd--untyped': state === LetterState.Untyped,
            'spell__kbd--next': state === LetterState.Next,
            'spell__kbd--typed': state === LetterState.Typed,
        });
        return (_jsx("span", { className: className, children: letter === ' ' ? '␣' : letter }, void 0));
    }
}
