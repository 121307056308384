import { Option } from './Option';
export class NullableStringOption extends Option {
    constructor(storage) {
        super(storage, null);
    }
    // eslint-disable-next-line class-methods-use-this
    decode(rawValue) {
        const value = rawValue.trim();
        return [value.length !== 0 ? value : null];
    }
    encode() {
        return this.value;
    }
}
