import { Texture } from '@babylonjs/core/Materials/Textures/texture';
import { Vector3 } from '@babylonjs/core/Maths/math.vector';
import { MeshBuilder } from '@babylonjs/core/Meshes/meshBuilder';
import { ParticleSystem } from '@babylonjs/core/Particles/particleSystem';
import sparkleUrl from '../assets/textures/sparkle.png';
export class ItemParticles {
    savedParticles;
    mesh;
    constructor(ctx, emitter, position = null) {
        this.savedParticles = new ParticleSystem('saved', 10, ctx.getScene());
        this.savedParticles.particleTexture = new Texture(sparkleUrl, ctx.getScene());
        const mesh = MeshBuilder.CreateBox('particle shape', { size: emitter.getBoundingInfo().boundingSphere.radius * 2 });
        this.mesh = mesh;
        mesh.position = position ?? emitter.position;
        mesh.isVisible = false;
        mesh.isPickable = false;
        this.savedParticles.emitter = mesh;
        this.savedParticles.minEmitBox = new Vector3(-2, -2, -2); // Starting all from
        this.savedParticles.maxEmitBox = new Vector3(2, 2, 2); // To...
        this.savedParticles.addVelocityGradient(0, 0.5);
        this.savedParticles.addVelocityGradient(1, 0.5);
        //increasing then decreasing size over lifetime
        for (let i = 0; i <= 2; i += 0.05) {
            this.savedParticles.addSizeGradient(i / 2, 0.5 * (1 - (i - 1) * (i - 1)));
        }
    }
    startSaved() {
        if (!this.savedParticles.isAlive()) {
            this.savedParticles.start();
        }
    }
    stopSaved() {
        if (this.savedParticles.isAlive()) {
            this.savedParticles.stop();
        }
    }
    setPosition(position) {
        this.mesh.position.copyFrom(position);
        this.mesh.computeWorldMatrix();
    }
}
