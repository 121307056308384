import { TransformNode } from '@babylonjs/core/Meshes/transformNode';
import { cycle } from '../cycle';
import { Interactable } from '../entities/Interactable';
import { NonTalkingInteractor } from '../interaction/NonTalkingInteractor';
import { TalkingInteractor } from '../interaction/TalkingInteractor';
import { nodeHasTag } from '../nodeHasTag';
import { parseLevel } from '../parseLevel';
import { shuffleInPlace } from '../shuffle';
export const initInteractable = (ctx, mesh) => {
    let idle = null;
    let unstable = null;
    ctx.getScene().animationGroups.forEach((a) => {
        if (mesh.name.includes('ManHead')) {
            if (a.name.toLowerCase().includes('man')) {
                if (a.name.toLowerCase().includes('idle')) {
                    idle = a;
                }
                if (a.name.toLowerCase().includes('unstable')) {
                    unstable = a;
                }
            }
        }
        a.targetedAnimations.forEach((t) => {
            if (t.target instanceof TransformNode) {
                if (t.target.parent === mesh.parent) {
                    if (t.animation.name.toLowerCase().includes('idle')) {
                        idle = a;
                    }
                    if (t.animation.name.toLowerCase().includes('unstable')) {
                        unstable = a;
                    }
                }
            }
        });
    });
    let interactor;
    const options = {};
    const level = parseLevel(mesh.name) ?? 1;
    if (level < 4) {
        // ====================
        // Weasel 1
        // ====================
        options.spellings = cycle(shuffleInPlace([
            'Where am I',
            'Where is this',
            'What is this place',
        ]));
        options.saved = cycle(shuffleInPlace([
            'This is the home he made us',
            'I feel alive. The creators palace awaits',
        ]));
    }
    else if (level < 5) {
        // ====================
        // Weasel 2 lvl 4.
        // ====================
        options.spellings = cycle(shuffleInPlace([
            'How does the ambience feel',
            'Is this the right mood',
        ]));
        options.saved = cycle(shuffleInPlace([
            'I will share this feeling with others',
        ]));
    }
    else if (level < 7) {
        // ====================
        // Weasel 3 lvl 6
        // ====================
        options.spellings = cycle(shuffleInPlace([
            'Essences effect your spirit',
            'Creates a serene atmosphere',
        ]));
        options.saved = cycle(shuffleInPlace([
            'Happy to hear you feel that way',
            'This has made my day',
        ]));
    }
    else if (level < 10) {
        // ====================
        // Toad lvl 9
        // ====================
        options.spellings = cycle(shuffleInPlace([
            'Aroma of a freshly cut hayfield',
            'Articulate the depth of colour',
        ]));
        options.saved = cycle(shuffleInPlace([
            'I am proud of you',
        ]));
    }
    else if (level < 21) {
        // ====================
        // Weasel lvl 20
        // ====================
        options.spellings = cycle(shuffleInPlace([
            'smell and feeling of everyday objects',
            'Green represents growth and healing',
            'try to describe colors and their effect',
        ]));
        options.saved = cycle(shuffleInPlace([
            'You have been working so hard',
        ]));
    }
    else if (level < 29) {
        // ====================
        // Toad lvl 28
        // ====================
        options.spellings = cycle(shuffleInPlace([
            'More than light or dark',
            'More than round or square',
            'More than shallow or deep',
            'More than striped or polka dot',
        ]));
        options.saved = cycle(shuffleInPlace([
            'I really appreciate you taking the time to do that',
        ]));
    }
    else if (level > 31) {
        // ====================
        // Boss
        // ====================
        options.greetings = cycle(shuffleInPlace([
            'My creation',
            'Finally we meet',
            'Come to me',
        ]));
        options.spellings = cycle(shuffleInPlace([
            'What does my creator want with me',
            'Prove that I can speak, for that I can',
            'Pack my box with five dozen liquor jugs',
            'the true art lies within the artistic process',
        ]));
        options.saved = () => {
            if (ctx.interactables.every(s => s.getSaved() || s.getMesh().name === mesh.name)) {
                return 'You made me proud. Well done for completing everything';
            }
            return 'This it the ending. Congrats.';
        };
    }
    if (nodeHasTag(mesh, 'Talks')) {
        interactor = new TalkingInteractor(options);
    }
    else {
        interactor = new NonTalkingInteractor();
    }
    let [savedSpot] = mesh.getChildMeshes(true, s => nodeHasTag(s, 'SavedSpot'));
    const interactable = new Interactable(ctx, mesh, interactor, idle, unstable, savedSpot === undefined ? null : savedSpot);
    ctx.addInteractable(interactable);
    return interactable;
};
