import { jsx as _jsx } from "react/jsx-runtime";
import { Component } from 'react';
const PENALTY = 200;
export class SpellTimer extends Component {
    bAnimate = this.animate.bind(this);
    animationHandle = null;
    state = {
        startedAt: Date.now(),
        percent1: 0,
        percent2: 0,
    };
    componentDidMount() {
        this.requestAnimationFrame();
    }
    componentWillUnmount() {
        if (this.animationHandle !== null) {
            cancelAnimationFrame(this.animationHandle);
            this.animationHandle = null;
        }
    }
    animate() {
        this.animationHandle = null;
        const { duration, misstypes } = this.props;
        const { startedAt } = this.state;
        const penalty = misstypes * PENALTY;
        const elapsed = Date.now() - startedAt;
        const done = elapsed + penalty > duration;
        let r1, r2;
        if (done) {
            r1 = penalty / (elapsed + penalty);
            r2 = elapsed / (elapsed + penalty);
        }
        else {
            r1 = penalty / duration;
            r2 = elapsed / duration;
        }
        this.setState({
            percent1: r1 * 100,
            percent2: r2 * 100,
        });
        if (done) {
            this.props.onFinished();
            return;
        }
        this.requestAnimationFrame();
    }
    requestAnimationFrame() {
        this.animationHandle = requestAnimationFrame(this.bAnimate);
    }
    render() {
        const { percent1: p1, percent2: p2 } = this.state;
        const d = 0.001;
        const style = {
            background: `linear-gradient(
        0.25turn,
        green,
        lime ${100 - p1 - p2 - d}%,
        purple ${100 - p1 - p2}%,
        purple ${100 - p2 - d}%,
        red ${100 - p2}%,
        darkred
      )`,
        };
        const { duration, misstypes } = this.props;
        const { startedAt } = this.state;
        let remaining = duration - Date.now() + startedAt - (misstypes * PENALTY);
        if (remaining < 0) {
            remaining = 0;
        }
        else if (remaining > duration) {
            remaining = duration;
        }
        remaining /= 1000;
        return (_jsx("div", { className: "spell__timer", style: style, children: remaining.toFixed(2).padStart(5, '0').replace('.', ':') }, void 0));
    }
}
