import { KeyboardControls } from './KeyboardControls';
export class ControlsManager {
    keyboardControls;
    enabled = false;
    constructor(ctx) {
        this.keyboardControls = new KeyboardControls(ctx);
        this.updateControlsEnabled();
    }
    updateControlsEnabled() {
        this.keyboardControls.setEnabled(this.enabled);
    }
    setEnabled(enabled) {
        this.enabled = enabled;
        this.updateControlsEnabled();
    }
    dispose() {
        this.keyboardControls.dispose();
    }
}
