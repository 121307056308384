import { Engine } from '@babylonjs/core/Engines/engine';
import SamJs from 'sam-js/dist/samjs.min.js';
import { checkNotNull } from '../checkNotNull';
import { Hub } from '../Hub';
const handleSay = (text, options) => {
    const ctx = checkNotNull(Engine.audioEngine.audioContext);
    const sam = new SamJs(options);
    const buffer = sam.buf32(text);
    if (buffer === false) {
        return;
    }
    let source = ctx.createBufferSource();
    let soundBuffer = ctx.createBuffer(1, buffer.length, 22050);
    let data = soundBuffer.getChannelData(0);
    for (let i = 0; i < buffer.length; ++i) {
        data[i] = buffer[i];
    }
    source.buffer = soundBuffer;
    source.connect(ctx.destination);
    source.start(0);
    source.onended = () => {
        Hub.said.emit(text);
    };
};
export class TextToSpeech {
    offSay = Hub.say.watch(handleSay.bind(this));
    dispose() {
        this.offSay();
    }
}
