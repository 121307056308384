import { StandardMaterial } from '@babylonjs/core/Materials/standardMaterial';
import { Color3 } from '@babylonjs/core/Maths/math.color';
import { Mesh } from '@babylonjs/core/Meshes/mesh';
export const initCameraTarget = (ctx) => {
    const scene = ctx.getScene();
    const cameraTarget = Mesh.CreateSphere('cameraTarget', 4, 1, scene);
    cameraTarget.isPickable = false;
    const material = new StandardMaterial('cameraTargetMaterial', scene);
    material.diffuseColor = Color3.Red();
    cameraTarget.material = material;
    ctx.setCameraTarget(cameraTarget);
};
