export var Key;
(function (Key) {
    Key["Cheat"] = "cheat";
    Key["Intro"] = "intro";
    Key["Intructions"] = "instructions";
    Key["Level"] = "level";
    Key["Mouse"] = "mouse";
    Key["Music"] = "music";
    Key["Mute"] = "mute";
    Key["Pour"] = "pour";
    Key["Start"] = "start";
    Key["Water"] = "water";
})(Key || (Key = {}));
