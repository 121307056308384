import { ControlsMode } from './ControlsMode';
const options = {
    passive: true,
};
export class KeyboardControls {
    ctx;
    enabled = false;
    constructor(ctx) {
        this.ctx = ctx;
        window.addEventListener('blur', this.bHandleBlur, options);
        document.addEventListener('visibilitychange', this.bHandleVisibilityChange, options);
        window.addEventListener('keydown', this.bHandleKeyDown, options);
        window.addEventListener('keyup', this.bHandleKeyUp, options);
    }
    dispose() {
        window.removeEventListener('keyup', this.bHandleKeyUp);
        window.removeEventListener('keydown', this.bHandleKeyDown);
        document.removeEventListener('visibilitychange', this.bHandleVisibilityChange);
        window.removeEventListener('blur', this.bHandleBlur);
    }
    setEnabled(enabled) {
        this.enabled = enabled;
    }
    bHandleKeyDown = this.handleKeyDown.bind(this);
    handleKeyDown(e) {
        if (!this.enabled) {
            return;
        }
        const controls = this.ctx.getControls();
        controls.setControlsMode(ControlsMode.Keyboard);
        switch (e.key.toLowerCase()) {
            case ' ':
                controls.interact.press();
                break;
            case 'arrowleft':
            case 'a':
                controls.left.press();
                break;
            case 'arrowright':
            case 'd':
                controls.right.press();
                break;
            case 'e':
                controls.raiseWater.press();
                break;
            case 'g':
                if (e.shiftKey) {
                    controls.debug.press();
                }
                break;
            case 'r':
                // controls.respawn.press();
                break;
            case 'arrowdown':
            case 's':
                controls.down.press();
                break;
            case 'shift':
                controls.raise.press();
                break;
            case 'arrowup':
            case 'w':
                controls.up.press();
                break;
            default:
                break;
        }
    }
    bHandleKeyUp = this.handleKeyUp.bind(this);
    handleKeyUp(e) {
        if (!this.enabled) {
            return;
        }
        const controls = this.ctx.getControls();
        switch (e.key.toLowerCase()) {
            case ' ':
                controls.interact.release();
                break;
            case 'arrowleft':
            case 'a':
                controls.left.release();
                break;
            case 'arrowright':
            case 'd':
                controls.right.release();
                break;
            case 'e':
                controls.raiseWater.release();
                break;
            case 'g':
                controls.debug.release();
                break;
            case 'r':
                // controls.respawn.release();
                break;
            case 'arrowdown':
            case 's':
                controls.down.release();
                break;
            case 'shift':
                controls.raise.release();
                break;
            case 'arrowup':
            case 'w':
                controls.up.release();
                break;
            default:
                break;
        }
    }
    releaseAllControls() {
        if (!this.enabled) {
            return;
        }
        this.ctx.getControls().debug.release();
        this.ctx.getControls().up.release();
        this.ctx.getControls().left.release();
        this.ctx.getControls().right.release();
        this.ctx.getControls().down.release();
        this.ctx.getControls().raise.release();
        this.ctx.getControls().interact.release();
        this.ctx.getControls().respawn.release();
        this.ctx.getControls().raiseWater.release();
    }
    bHandleBlur = this.handleBlur.bind(this);
    handleBlur() {
        this.releaseAllControls();
    }
    bHandleVisibilityChange = this.handleVisibilityChange.bind(this);
    handleVisibilityChange() {
        if (document.visibilityState === 'hidden') {
            this.releaseAllControls();
        }
    }
}
